import Event from './Event.js';
/**!
 * NeuLion Component Library v1.24.20191029
 */
(function (window, document, module) {
	var NEU = {};

	// --------------------- Env Start ---------------------

	(function (window, NEU) {
		var supportLocalStorage = false;

		function detectLocalStorage() {
			var test = 'test';
			try {
				window.localStorage.setItem(test, test);
				window.localStorage.removeItem(test);
				return true;
			}
			catch (e) {
				return false;
			}
		}

		if (detectLocalStorage()) {
			supportLocalStorage = true;
		}
		NEU.supportLocalStorage = supportLocalStorage;

		var supportSessionStorage = false;

		function detectSessionStorage() {
			var test = 'test';
			try {
				window.sessionStorage.setItem(test, test);
				window.sessionStorage.removeItem(test);
				return true;
			}
			catch (e) {
				return false;
			}
		}

		if (detectSessionStorage()) {
			supportSessionStorage = true;
		}
		NEU.supportSessionStorage = supportSessionStorage;
	})(window, NEU);

	// Backward compatible
	NEU.env = {};
	NEU.env.supportLocalStorage = NEU.supportLocalStorage;
	NEU.env.supportSessionStorage = NEU.supportSessionStorage;

	// --------------------- Env end ---------------------

	// Logger
	(function (window, NEU) {
		var Logger = {
			"OFF": 10,
			"ERROR": 5,
			"WARN": 3,
			"INFO": 2,
			"DEBUG": 1,
			"ALL": 0,

			setLevel: function (level) {
				this.level = level;
				if (NEU.supportSessionStorage) {
					window.sessionStorage.setItem("NEU.logger.level", this.level);
				}
			},
			getLevel: function () {
				var level = null;
				if (NEU.supportSessionStorage) {
					level = window.sessionStorage.getItem("NEU.logger.level");
				}
				return level != null ? level : this.ERROR;
			},

			_init: function () {
				this.level = this.getLevel();
			},

			_getTimeStamp: function () {
				var now = new Date();
				var time = [];
				["Hours", "Minutes", "Seconds", "Milliseconds"].forEach(function (item) {
					var val = now["get" + item]();
					time.push(val > 9 ? val : "0" + val);
				});
				return time.join(":");
			}
		};

		["debug", "info", "warn", "error"].forEach(function (item) {
			Logger[item] = function (message) {
				var level = this[item.toUpperCase()];
				if (level >= this.level) {
					try {
						var msg = Logger._getTimeStamp() + " [" + item.toUpperCase() + "] " + message;
						window.console.log.apply(window.console, [msg]);
					}
					catch (e) {
					}
				}
			};
		});

		Logger._init();

		NEU.logger = Logger;
	})(window, NEU);

	// --------------------- Util Start ---------------------
	NEU.util = {};

	(function (document, NEU) {
		// https://github.com/ded/domready
		NEU.domready = function () {
			var fns = [], listener
					, doc = typeof document === 'object' && document
					, hack = doc && doc.documentElement.doScroll
					, domContentLoaded = 'DOMContentLoaded'
					, loaded = doc && (hack ? /^loaded|^c/ : /^loaded|^i|^c/).test(doc.readyState);


			if (!loaded && doc)
				doc.addEventListener(domContentLoaded, listener = function () {
					doc.removeEventListener(domContentLoaded, listener);
					loaded = 1;
					while (listener = fns.shift()) listener()
				});

			return function (fn) {
				loaded ? setTimeout(fn, 0) : fns.push(fn)
			}
		}();
	}(document, NEU));

	(function () {
		var timeOffset = 0;
		/**
		 * Get Current Time.
		 * @param time  accurate time. Pass this value in only when you want to adjust time
		 * @returns {Date}
		 */
		NEU.now = function (time) {
			var now = Date && Date.now && Date.now() || new Date().getTime();

			if (time !== undefined) {
				// Adjust timeOffset
				if (typeof time === "number") {
					timeOffset = time - now;
				} else {
					timeOffset = new Date(time).getTime() - now;
				}
			} else {
				return new Date(now + timeOffset);
			}
		}
	})();

	/**
	 * Get Localized String from resource files
	 *
	 * getLocalizedString("am"); // It will read window.appLocaleText then fallback to regular dom selector
	 *                              This is compatible with function getLocalizedString() in base/site/scripts/util.js.
	 *                              If the key doesn't exist, it will still call alert()
	 *
	 * getLocalizedString("am", true);  // This is same as function getLocalizedString("am"),
	 *                                      but it key doesn't exist, it will return null
	 *
	 * getLocalizedString("am", "neu")  // Get key from window.appLocaleText['neu'][key]
	 *                                    then fallback to window.appLocaleText[key], then dom selector
	 *                                    There is no need to specify detectExist, it will be 'true' in this case by default.
	 *                                    Since v1.19.20181218
	 *
	 * @param key
	 * @param componentName    The
	 * @param detectExist    Default: 'false' if 'componentName' not specified; 'true' if specified.
	 *                      'true' means if key doesn't exist, return null instead of calling window.alert()
	 * @returns {*}
	 */
	function getLocalizedString(key, componentName, detectExist) {
		if (typeof componentName === "string") {
			// If 'componentName' being specified, set detectExist=true by default 
			detectExist = true;
		}
		else {
			detectExist = componentName;
			componentName = null;
		}
		var ret = null;
		if (componentName != null) {
			if (window.appLocaleText && window.appLocaleText[componentName]) {
				ret = window.appLocaleText[componentName][key];
			}
		}
		else if (window.appLocaleText) {
			ret = window.appLocaleText[key];
		}
		if (ret == null) {
			var obj = document.getElementById("msg_" + key);
			if (obj != null) {
				ret = obj.innerHTML;
				ret.replace(/\\n/g, "\n");
			}
		}
		if (ret == null && !detectExist) {
			alert("Error - Localization key '" + key + "' not found.");
		}
		else {
			return ret;
		}
	}

	NEU.getLocalizedString = getLocalizedString;

	/**
	 * parse standard date string to Date object
	 * @param dateStr  standard date string from app server: yyyy-MM-dd'T'HH:mm:ss.000, for example: 2016-03-05T09:15:31.000
	 */
	function parseDate(dateStr) {
		var date = NEU.now();
		date.setFullYear(parseInt(dateStr.substring(0, 4), 10), parseInt(dateStr.substring(5, 7), 10) - 1, parseInt(dateStr.substring(8, 10), 10));
		date.setHours(parseInt(dateStr.substring(11, 13), 10), parseInt(dateStr.substring(14, 16), 10), parseInt(dateStr.substring(17, 19), 10), parseInt(dateStr.substring(20, 23), 10));
		return date;
	}

	NEU.parseDate = parseDate;

	/**
	 * Format Date object or dateStr with date pattern
	 * Dependency:
	 * 1) locale: msg_am,msg_pm,msg_timezone,msg_month_abbr_full,msg_month_abbr,msg_day_abbr,msg_day
	 * @param dateVal  Date object or standard date string from app server: yyyy-MM-dd'T'HH:mm:ss.000, for example: 2016-03-05T09:15:31.000
	 * @param pattern  for example: EEEE, MMM d, yyyy h:mm a z
	 * @param intlOrLocales  Support localization component. You can pass in a locale component that support locale.get(key); or you can pass into a locale object that contains all text, like {"timezone": "EST"}
	 * @returns string  for example: Saturday, Mar 5, 2016 9:15 AM ET
	 */
	function formatDate(dateVal, pattern, intlOrLocales) {
		var date = null;
		if (typeof dateVal === "string") {
			date = parseDate(dateVal);
		}
		else {
			date = dateVal;
		}
		var regex = /M{1,4}|y{2,4}|([smhHd])\1?|E{3,4}|[az]|"[^"]*"|'[^']*'/g;
		var month = date.getMonth();
		var day = date.getDate();
		var year = date.getFullYear();
		var dayInWeek = date.getDay();
		var hour = date.getHours();
		var minute = date.getMinutes();
		var second = date.getSeconds();
		var getString = function (key) {
			return intlOrLocales != undefined ? (typeof intlOrLocales.get === "function" ? intlOrLocales.get(key) : (intlOrLocales[key] != undefined ? intlOrLocales[key] : getLocalizedString(key)))
					: getLocalizedString(key)
		};
		// FIXME: only reference locale key when being used.
		var locales = {
			"month_abbr": getString("month_abbr"),
			"month_abbr_full": getString("month_abbr_full"),
			"day_abbr": getString("day_abbr"),
			"day": getString("day"),
			"am": getString("am"),
			"pm": getString("pm"),
			"timezone": getString("timezone")
		};
		var flags = {
			"s": second,
			"ss": second < 10 ? "0" + second : second,
			"m": minute,
			"mm": minute < 10 ? "0" + minute : minute,
			"h": hour % 12 || 12,
			"hh": ((hour % 12 || 12) + '').length > 1 ? (hour % 12 || 12) : '0' + (hour % 12 || 12),
			"H": hour,
			"HH": hour < 10 ? "0" + hour : hour,
			"d": day,
			"dd": (day < 10 ? "0" + day : day),
			"M": (month + 1),
			"MM": (month + 1 + '').length > 1 ? (month + 1) : '0' + (month + 1),
			"MMM": locales["month_abbr"].split(",")[month],
			"MMMM": locales["month_abbr_full"].split(",")[month],
			"yy": year % 100,
			"yyyy": year + "",
			"EEE": locales["day_abbr"].split(",")[dayInWeek],
			"EEEE": locales["day"].split(",")[dayInWeek],
			"a": hour < 12 ? locales["am"] : locales["pm"],
			"z": locales["timezone"]
		};
		return pattern.replace(regex, function ($0) {
			return $0 in flags ? flags[$0] : $0;
		});
	}

	NEU.formatDate = formatDate;

	/**
	 * Convert (UTC) date(string) to local date with format pattern
	 * Dependency: moment.js (only if the pattern param has 'z' which means output with timezone abbr)
	 * @param {date|string} utcDateOrUTCString
	 * @param {string} pattern
	 * @param {instance|object} intlOrLocales
	 * @return {string|*}
	 */
	function formatDateToLocalDate(utcDateOrUTCString, pattern, intlOrLocales) {
		var timeDate = utcDateOrUTCString;
		if (typeof utcDateOrUTCString === 'string') {
			timeDate = new Date(utcDateOrUTCString + (utcDateOrUTCString.indexOf('Z') !== -1 ? '' : 'Z'));
		}
		intlOrLocales = intlOrLocales || (pattern.toLowerCase().indexOf('z') < 0 ? undefined : {timezone: moment(timeDate).tz(moment.tz.guess()).zoneAbbr()});
		return NEU.formatDate(timeDate, pattern, intlOrLocales);
	}

	NEU.formatDateToLocalDate = formatDateToLocalDate;

	/**
	 * Convert page [data-toggle="convertLocalDate"] element content with local date format
	 * Required:
	 * 1. element attribute: data-toggle="convertLocalDate";
	 * 2. element attribute: data-time="1530088647704";
	 *  2.1 In JSP: data-time="${programDateValue.time}",
	 *  such as programDateValue = ${requestScope.events[0].release_date}, ${requestScope.events[0].begin_date_time}, ${requestScope.program.release_date}, ${requestScope.program.begin_date_time}
	 *  2.2 In JS: data-time=new Date(program.beginDateTimeGMT + 'Z').getTime()
	 * 3. element attribute: data-pattern="pattern", such as pattern="HH:mm", it may came from NEU.getLocalizedString("date_format")
	 */
	function convertToLocalDate() {
		Array.prototype.slice.call(document.querySelectorAll('[data-toggle="convertLocalDate"]'))
				.forEach(function (element) {
					var timeDate = new Date(element.getAttribute('data-time') / 1),
							dateFormat = element.getAttribute('data-pattern') || NEU.getLocalizedString("date_format");
					element.innerHTML = formatDateToLocalDate(timeDate, dateFormat);
					element.removeAttribute('data-toggle');
				});
	}

	NEU.convertToLocalDate = convertToLocalDate;

	/**
	 * Date util, to easily compare the time in GMT / Server time zone / Project time zone
	 * @param clientTimeOffset  This is to remove the influence of the client time deviation. In seconds.
	 * @param dateObj additional date values, like:
	 *    "serverTimeOffset": Time offset between Server time zone and GMT. This is usually the ET. In seconds.
	 *    "projectTimeOffset": Time offset between Project time zone and GMT. In seconds.
	 * @constructor
	 */
	function DateUtil(clientTimeOffset, dateObj) {
		this.getTime = function () {
			return new Date(NEU.now().getTime() - clientTimeOffset);
		};

		/**
		 * Compare UTC
		 * @param timeStr  yyyy-MM-dd'T'HH:mm:ss.SSS or yyyy-MM-dd'T'HH:mm:ss
		 * @returns {boolean}
		 */
		this.compareUTCTime = function (timeStr) {
			return this.getTime().getTime() >= new Date(timeStr + "Z").getTime();
		};

		/**
		 * Compare server time
		 * NOTE: DST issue here. There will be 1 hour difference during the time when DST switch. This happened twice a year.
		 * @param timeStr  yyyy-MM-dd'T'HH:mm:ss.SSS or yyyy-MM-dd'T'HH:mm:ss
		 * @returns {boolean}
		 */
		this.compareServerTime = function (timeStr) {
			if (dateObj && dateObj.serverTimeOffset != undefined) {
				var serverTime = new Date(this.getTime().getTime() + dateObj.serverTimeOffset);
				return serverTime.getTime() >= new Date(timeStr + "Z").getTime();
			}
			else {
				alert("serverTimeOffset not initialized");
			}
		};

		/**
		 * Compare Project time
		 * NOTE: DST issue here. There will be 1 hour difference during the time when DST switch. This happened twice a year.
		 * @param timeStr  yyyy-MM-dd'T'HH:mm:ss.SSS or yyyy-MM-dd'T'HH:mm:ss
		 * @returns {boolean}
		 */
		this.compareProjectTime = function (timeStr) {
			if (dateObj && dateObj.projectTimeOffset != undefined) {
				var projectTime = new Date(this.getTime().getTime() + dateObj.projectTimeOffset);
				return projectTime.getTime() >= new Date(timeStr + "Z").getTime();
			}
			else {
				alert("projectTimeOffset not initialized");
			}
		};
	}

	NEU.DateUtil = DateUtil;

	function setCookie(name, value, days) {
		var expires;
		if (days) {
			var date = NEU.now();
			date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
			expires = "; expires=" + date.toGMTString();
		}
		else {
			expires = "";
		}
		document.cookie = name + "=" + value + expires + "; path=/";
	}

	NEU.setCookie = setCookie;

	function getCookie(name) {
		if (document.cookie.length > 0) {
			var startIdx, endIdx;
			startIdx = document.cookie.indexOf(name + "=");
			if (startIdx != -1) {
				startIdx = startIdx + name.length + 1;
				endIdx = document.cookie.indexOf(";", startIdx);
				if (endIdx == -1) {
					endIdx = document.cookie.length;
				}
				return unescape(document.cookie.substring(startIdx, endIdx));
			}
		}
		return null;
	}

	NEU.getCookie = getCookie;

	function delCookie(name) {
		setCookie(name, null, -1);
	}

	NEU.delCookie = delCookie;

	// --------------------- Ajax ---------------------

	var ajaxDefault = {
		round: undefined,	// FIXME: maybe we should set 60s by default?
		timeout: 10000
	};

	function ajaxSetup(settings) {
		if (settings) {
			for (var key in settings) {
				ajaxDefault[key] = settings[key];
			}
		}
	}

	NEU.ajaxSetup = ajaxSetup;

	/**
	 * Load a Javascript. This is an alternative of jQuery.getScript in native JavaScript
	 * Ref: https://code.i-harness.com/en/q/100f412
	 *
	 * @param url
	 * @param callback  successfull callback
	 * @param options  e.g.: error, timeout, round
	 */
	function getScript(url, callback, options) {
		var script = document.createElement('script'),
				prior = document.getElementsByTagName('script')[0],
				isIE9 = (document.documentMode === 9);
		script.async = true;
		var intervalId,
				timeout = options && options.timeout || ajaxDefault.timeout;
		var round = options && options.round || ajaxDefault.round;
		if (round) {
			url = addTimestamp(url, round);
		}

		script.onload = script.onreadystatechange = script.onerror = function (_, isAbort) {
			if (_ && _.type === 'error') {
				if (intervalId) {
					clearTimeout(intervalId);
					intervalId = null;
				}
				script.onload = script.onreadystatechange = script.onerror = null;
				script.parentElement.removeChild(script);
				script = undefined;
				options && options.error && options.error();
			}
			else if (isAbort || (script && (!script.readyState || /loaded|complete/.test(script.readyState)))) {
				if (intervalId) {
					clearTimeout(intervalId);
					intervalId = null;
				}
				script.onload = script.onreadystatechange = null;
				if (!isIE9) {
					script.onerror = null;
				}

				// Remove the script
				if (isAbort) {
					if (script.parentNode) {
						script.parentNode.removeChild(script);
					}
				}

				if (!document.documentMode || document.documentMode >= 10) {
					script.parentElement.removeChild(script);
					script = undefined;
				}

				if (!isIE9) {
					!isAbort && callback && callback(true);
				}
				else {
					setTimeout(function () {
						!isAbort && callback && callback(true);
					}, 0);
				}
			}
		};

		script.src = url;
		prior.parentNode.insertBefore(script, prior);

		if (timeout) {
			intervalId = setTimeout(function () {
				// Known issue: script that is timeout, can't be really canceled
				script.onload(undefined, true);
				options && options.error && options.error();
			}, timeout);
		}
	}

	NEU.getScript = getScript;

	function fetchScript(url, options) {
		return new Promise((resolve, reject) => {
			if (options === undefined) options = {};
			options.error = reject;
			NEU.getScript(url, function (data) {
				resolve(data);
			}, options);
		});
	}

	NEU.fetchScript = fetchScript;

	/**
	 * It can be used to get json API/Service in same domain, or different domain with CORS support.
	 * This is an alternative to jQuery.getJSON()
	 * @param url
	 * @param callback
	 * @param options  e.g.: data, token, method, error, round
	 */
	function getJSON(url, callback, options) {
		// Backward compatible
		if (typeof options === 'function') {
			options = {"error": options};
		}

		if (options == undefined) {
			options = {};
		}

		var request = new XMLHttpRequest();
		var jsonUrl = url;
		var round = options.round || ajaxDefault.round;
		if (round) {
			jsonUrl = addTimestamp(jsonUrl, round);
		}
		if (options.data) {
			jsonUrl = appendData(url, options.data);
		}
		var method = options.method || "GET";
		request.open(method, jsonUrl, true);
		request.responseType = "json";

		if (options.token) {
			// Setting request header will trigger pre-flight call.
			// Explanation: https://stackoverflow.com/questions/29954037/why-is-an-options-request-sent-and-can-i-disable-it
			request.setRequestHeader("Content-Type", "application/json");
			request.setRequestHeader("Accept", "application/json");
			request.setRequestHeader("Authorization", "Bearer " + options.token);
		}

		if (window.withCredentials) {
			request.withCredentials = true;
		}
		request.onload = function () {
			// Success
			if (request.status >= 200 && request.status < 400) {
				var data;
				// when the responseType is set to 'json', xhr.response must be used instead of xhr.responseText. 
				// When the browser fails to parse the response as JSON, null is returned (instead of throwing an error).
				if (request.responseType == "json") {
					data = request.response;
					// Not all browser support responseType. IE still return string
					if (typeof data === "string") {
						data = JSON.parse(data);
					}
				} else {
					data = JSON.parse(request.responseText);
				}
				if (callback) callback(data);
			}
			else {
				// We reached our target server, but it returned an error
				if (options.error) options.error(request.status);
			}
		};

		request.onerror = function () {
			// There was a connection error of some sort
			if (options.error) options.error();
		};

		request.send();
	}

	NEU.getJSON = getJSON;

	function fetchJSON(url, options) {
		return new Promise((resolve, reject) => {
			if (options === undefined) options = {};
			options.error = reject;
			NEU.getJSON(url, function (data) {
				resolve(data);
			}, options);
		});
	}

	NEU.fetchJSON = fetchJSON;

	(function (window, document, NEU) {
		var callbackFuncName = "neuJsonCallback",
				jsonDataQueue = [],
				scriptCallbackCache = {},
				isOpera = typeof opera !== 'undefined' && opera.toString() === '[object Opera]';

		// Detect script 'interactive' readyState to get the script that is currently processing. This is for IE<=10
		var useInteractive,
				interactiveScript,
				currentlyAddingScript;

		/**
		 * Load a JSONP Service
		 * TODO: Merge into getJSON(). Detect cross domain and '[cb]' to determine to use ajax or jsonp
		 *
		 * @param url. Please use '[cb]' as the value of the callback URL parameters.
		 *    For example: 'json.wrf=[cb]', '/service/config?format=json&callback=[cb]'
		 * @param callback
		 * @param options  e.g.: error, round
		 */
		function getJSONP(url, callback, options) {
			var jsonUrl = url.replace(/\[cb]/, callbackFuncName);
			var round = options && options.round || ajaxDefault.round;
			if (round) {
				jsonUrl = NEU.addTimestamp(jsonUrl, round);
			}

			var uuid = NEU.createUUID();
			loadScript(uuid, jsonUrl, function (evt, success) {
				var script = evt.currentTarget || evt.srcElement;
				var id = script.getAttribute('data-id');
				onJSONPLoad(id, success, callback, options && options.error);
			});
		}

		function onJSONPLoad(id, success, callback, errCallback) {
			if (success) {
				var found = false;
				for (var i = 0; i < jsonDataQueue.length; i++) {
					// Find the data in the queue.
					// The "loaded" event will be triggered following the same sequence as the script being executed
					if (jsonDataQueue[i].data != null && (!useInteractive || !jsonDataQueue[i].id || jsonDataQueue[i].id === id)) {
						callback && callback(jsonDataQueue[i].data);
						jsonDataQueue.splice(i, 1);
						found = true;
						break;
					}
				}
				// Only in IE10 when script being cached, because 'loaded' event will be triggered after script 'executed',
				// the data will not be found, put the loaded status in queue.
				if (!found) {
					NEU.logger.info("script is cached by browser. The 'onload' triggered before script being executed.");
					jsonDataQueue.push({"id": id, "loaded": true, "callback": callback});
				}
			}
			else {
				if (errCallback) {
					errCallback();
				}
			}
		}

		function dataCallback(data) {
			var found = false,
					id;
			// In IE10, when script is being cached, 'loaded' event will be triggered before script being executed.
			// This is to trigger callback function when script being executed, after 'loaded' event.
			// Using 'id' here is accurately find the target script that is loaded,
			// Because in IE10 the sequence of 'loaded(cache)' and 'execute' are not always the same.
			if (useInteractive) {
				var script = currentlyAddingScript || getInteractiveScript();
				// When script will be null?
				if (script) {
					id = script.getAttribute('data-id');
					for (var i = 0; i < jsonDataQueue.length; i++) {
						if (jsonDataQueue[i].id === id && jsonDataQueue[i].loaded) {
							NEU.logger.info("'onload' already triggered, execute callback function directly");
							jsonDataQueue[i].callback && jsonDataQueue[i].callback(data);
							jsonDataQueue.splice(i, 1);
							found = true;
							break;
						}
					}
				}
			}
			if (!found) {
				if (id) {
					jsonDataQueue.push({"id": id, "data": data});
				}
				else {
					jsonDataQueue.push({"data": data});
				}
			}
		}

		window[callbackFuncName] = dataCallback;

		function loadScript(id, url, callback) {
			var script = document.createElement('script'),
					prior = document.getElementsByTagName('script')[0];
			script.setAttribute('data-id', id);
			script.async = true;

			if (script.attachEvent &&
					!(script.attachEvent.toString && script.attachEvent.toString().indexOf('[native code') < 0) &&
					!isOpera) {
				useInteractive = true;
				script.attachEvent('onreadystatechange', onScriptLoad);
			}
			else {
				script.addEventListener('load', onScriptLoad, false);
				// TODO: onerror
				// script.addEventListener('error', onScriptLoad, false);
			}

			scriptCallbackCache[id] = callback;

			script.src = url;
			currentlyAddingScript = script;
			prior.parentNode.insertBefore(script, prior);
			currentlyAddingScript = null;
		}

		function onScriptLoad(evt, isAbort) {
			var script = evt.currentTarget || evt.srcElement;

			if (evt.type === 'error'
					|| (isAbort || (script && (!script.readyState || /loaded|complete/.test(script.readyState))))) {
				var id = script.getAttribute('data-id');
				var callback = scriptCallbackCache[id];
				delete scriptCallbackCache[id];

				if (script.attachEvent &&
						!(script.attachEvent.toString && script.attachEvent.toString().indexOf('[native code') < 0) &&
						!isOpera) {
					script.detachEvent('onreadystatechange', onScriptLoad);
				}
				else {
					script.removeEventListener('load', onScriptLoad, false);
					// TODO: onerror
					// script.removeEventListener('error', onScriptError, false);
				}

				if (evt.type === 'error') {
					script.parentElement.removeChild(script);
					script = undefined;
					callback && callback(evt, false);
				}
				else if (isAbort || (script && (!script.readyState || /loaded|complete/.test(script.readyState)))) {
					if (!document.documentMode || document.documentMode >= 10) {
						script.parentElement.removeChild(script);
						script = undefined;
					}
					!isAbort && callback && callback(evt, true);
				}
			}
		}

		/**
		 * For IE<=10
		 * Ref: https://github.com/requirejs/requirejs/blob/master/require.js
		 */
		function getInteractiveScript() {
			if (interactiveScript && interactiveScript.readyState === 'interactive') {
				return interactiveScript;
			}

			eachReverse(scripts(), function (script) {
				if (script.readyState === 'interactive') {
					return (interactiveScript = script);
				}
			});
			return interactiveScript;
		}

		/**
		 * Ref: https://github.com/requirejs/requirejs/blob/master/require.js
		 */
		function scripts() {
			return document.getElementsByTagName('script');
		}

		/**
		 * Helper function for iterating over an array backwards. If the func
		 * returns a true value, it will break out of the loop.
		 *
		 * Ref: https://github.com/requirejs/requirejs/blob/master/require.js
		 */
		function eachReverse(ary, func) {
			if (ary) {
				var i;
				for (i = ary.length - 1; i > -1; i -= 1) {
					if (ary[i] && func(ary[i], i, ary)) {
						break;
					}
				}
			}
		}

		NEU.getJSONP = getJSONP;
	}(window, document, NEU));

	function fetchJSONP(url, options) {
		return new Promise((resolve, reject) => {
			if (options === undefined) options = {};
			options.error = reject;
			NEU.getJSONP(url, function (data) {
				resolve(data);
			}, options);
		});
	}

	NEU.fetchJSONP = fetchJSONP;

	/**
	 * Load a named JSONP feed.
	 * @param url
	 * @param funcName  The function name hardcoded in jsonp feed
	 * @param callback
	 * @param options  e.g.: error, round
	 */
	function getJSONPFeed(url, funcName, callback, options) {
		if (funcName == undefined) {
			alert("'funcName' is undefined!");
		}
		if (window.__jsonFeedCache === undefined) {
			window.__jsonFeedCache = {};
		}
		if (window[funcName] === undefined) {
			window[funcName] = function (data) {
				__jsonFeedCache[funcName] = data;
			};
		}
		getScript(url, function () {
			callback && callback(__jsonFeedCache[funcName]);
		}, options);
	}

	NEU.getJSONPFeed = getJSONPFeed;

	function fetchJSONPFeed(url, funcName, options) {
		return new Promise((resolve, reject) => {
			if (options === undefined) options = {};
			options.error = reject;
			NEU.getJSONPFeed(url, funcName, function (data) {
				resolve(data);
			}, options);
		});
	}

	NEU.fetchJSONPFeed = fetchJSONPFeed;

	/**
	 * Load a javascript feed and the data is defined in the feed as a global object.
	 * @param url
	 * @param objName   The objName hardcoded in JS feed
	 * @param callback
	 */
	function getJSFeed(url, objName, callback) {
		getScript(url, function () {
			callback && callback(window[objName]);
		});
	}

	NEU.getJSFeed = getJSFeed;

	/**
	 * Add timestamp to url to avoid being cached by browser.
	 * Note: param 'round' is not required to NeuLion CDN anymore because our CDN will ignore param.t
	 *       Before we use 'round' to let the request URL to be the same one during a short time window,
	 *       to avoid CDN requesting original server too frequently.
	 * @param url
	 * @param round
	 * @returns {string}
	 */
	function addTimestamp(url, round) {
		return url + (url.indexOf('?') > -1 ? '&' : '?') + 't=' + parseInt(NEU.now().getTime() / (round ? round * 1000 : 1));
	}

	NEU.addTimestamp = addTimestamp;

	function appendData(url, data) {
		var params = Object.keys(data).map(function (k) {
			return encodeURIComponent(k) + '=' + encodeURIComponent(data[k])
		}).join('&');
		return url + (url.indexOf('?') > -1 ? '&' : '?') + params;
	}

	/**
	 * Extend function
	 * @param fnName  type: string. function name under windows that needs to be extended.
	 * @param extendedFn  type: function.
	 */
	function extendFunction(fnName, extendedFn) {
		var fn;
		if (fnName.indexOf(".") == -1) {
			fn = window[fnName];
		}
		else {
			if (window[fnName.split(".")[0]] != undefined) {
				fn = window[fnName.split(".")[0]][fnName.split(".")[1]];
			}
		}
		if (typeof fn === "function") {
			var extFnName = "__neuExtend__" + fnName;	// TODO: handle duplicated name?
			window[extFnName] = fn;
			var newFn = function () {
				var ret = window[extFnName].apply(this, arguments);
				return extendedFn.call(this, arguments, ret);
			};
			if (fnName.indexOf(".") > -1) {
				window[fnName.split(".")[0]][fnName.split(".")[1]] = newFn;
			}
			else {
				window[fnName] = newFn;
			}
		}
		else {
			NEU.logger.error("Function: '" + fnName + "' is undefined or is not a function.");
		}
	}

	NEU.extendFunction = extendFunction;

	// --------------------- Project Common ---------------------
	/**
	 * @deprecated please just use latest 'video_unsupported', reference to test/app/neu/check_h5player_compatibility.jsp
	 *
	 * When NeuLion H5 Player throws unsupported exception, use this function to show proper message to user.
	 *  {[supported, message]}
	 * Dependency:
	 * 1) locale: unsupported,video_unsupported_windows7,video_unsupported_windows,video_unsupported_safari,video_unsupported_mac_firefox
	 * @param userAgent navigator.userAgent
	 * @param drm Enable DRM or not
	 * @returns {[supported, message]} supported=true means with proper configuration, player can be supported;
	 */
	function checkH5PlayerCompatibility(userAgent, drm) {
		/**
		 * 1) Windows 7, all IE doesn't support (Windows NT == 6.1 and isIE())
		 * 2) lower than Windows 7 (Windows XP), all browser doesn't support (Windows NT < 6.1)
		 * 3) Mac Safari. if not supported, prompt a message
		 * 4) Mac Firefox (not sure why it has been disabled in SkyBoxOffice)
		 * 5) iOS 9+. disabled if drm=true
		 * 6) android 4.4+. disabled in Firefox if drm=true
		 */
		var ua = userAgent.toLowerCase();
		var isWindows = userAgent.match(/Windows NT/) != null;
		var isMac = userAgent.indexOf("Macintosh") != -1;
		var winVersion = null;
		if (isWindows && userAgent.match(/Windows NT (\d+[\.]{0,1}\d+)/).length == 2) {
			winVersion = userAgent.match(/Windows NT (\d+[\.]{0,1}\d+)/)[1] / 1;
		}
		var isIE = "ActiveXObject" in window;
		var isEdge = userAgent.indexOf("Edge") != -1;
		var isChrome = userAgent.indexOf("Chrome") != -1 || userAgent.indexOf("CriOS") != -1;
		var isFirefox = userAgent.indexOf("Firefox") != -1;
		var isSafari = !isChrome && !isFirefox && !isEdge && userAgent.indexOf("Safari") != -1;
		var isAndroid = ua.indexOf("android") != -1;
		var isIOS = ua.indexOf("iphone") != -1 || ua.indexOf("ipad") != -1;
		var supported = true;
		var defaultMessage = getLocalizedString("unsupported");
		var message = null;

		if (isWindows && winVersion == 6.1 && isIE) {
			message = getLocalizedString("video_unsupported_windows7", true);
		}
		else if (isWindows && (winVersion != null && winVersion < 6.1)) {
			message = getLocalizedString("video_unsupported_windows", true);
		}
		else if (isMac && isSafari) {
			message = getLocalizedString("video_unsupported_safari", true);
		}
		else if (isMac && isFirefox) {
			message = getLocalizedString("video_unsupported_mac_firefox", true);
		}
		else if (isIOS && isSafari) {
			message = getLocalizedString("video_unsupported_ios_safari", true);
			if (message == null) {
				message = getLocalizedString("video_unsupported_ios", true);
			}
		}
		else if (isIOS) {
			message = getLocalizedString("video_unsupported_ios", true);
			if (message == null) {
				message = getLocalizedString("video_unsupported_ios_safari", true);
			}
		}

		if (message == null) {
			message = defaultMessage;
		}

		if (isWindows && winVersion == 6.1 && isIE) {
			supported = false;
		}
		else if (isWindows && (winVersion != null && winVersion < 6.1)) {
			supported = false;
		}
		else if (isIOS && drm || isAndroid && drm && isFirefox) {
			supported = false;
		}
		return [supported, message];
	}

	NEU.checkH5PlayerCompatibility = checkH5PlayerCompatibility;

	/**
	 * Convert some solr specific attributes to app server version.
	 * @param program Solr Program Object
	 * @returns {*}
	 */
	function processSolrProgram(program) {
		program.id = program.pid;
		if (program.releaseDate != null)    // VOD
		{
			program.releaseDateGMT = program.releaseDate.replace("Z", ".000");
			// Use releaseDateLocal by priority. This is project timezone. So far, only UFC has releaseDateLocal
			var releaseDate = program.releaseDateLocal || program.releaseDate;
			// Convert data format: solr format 2016-02-07T03:00:00Z -> app server format 2016-02-07T03:00:00.000
			program._releaseDate = program.releaseDate;
			program.releaseDate = releaseDate.replace("Z", ".000");
		}
		if (program.beginDateTime != null)  // Live Event
		{
			program.beginDateTimeGMT = program.beginDateTime.replace("Z", ".000");
			var beginDateTime = program.beginDateTimeLocal || program.beginDateTime;
			program._beginDateTime = program.beginDateTime;
			program.beginDateTime = beginDateTime.replace("Z", ".000");
		}
		if (program.endDateTime != null) {
			program.endDateTimeGMT = program.endDateTime.replace("Z", ".000");
			var endDateTime = program.endDateTimeLocal || program.endDateTime;
			program._endDateTime = program.endDateTime;
			program.endDateTime = endDateTime.replace("Z", ".000");
		}
		// "liveState" 
		if (program.progType == 3 || program.progType == 5) {
			var isLiveEvent = true;
			if (program._beginDateTime != null) {
				var liveState = 0;
				var now = NEU.now();	// Local date is not reliable, but using server date is much expensive here.
				if (now.getTime() < new Date(program._beginDateTime).getTime()) {
					liveState = 0;
				} else {
					if (program._endDateTime != null && new Date(program._endDateTime).getTime() < now.getTime()) {
						liveState = 2;
					} else {
						liveState = 1;
					}
				}
				program.liveState = liveState;
			}
			program.isLiveEvent = isLiveEvent;
		}

		var runtimeMins = "",
				runtimeHours = "";
		var timeInt = program.runtime;
		var min = parseInt(timeInt / 60, 10);
		var sec = timeInt % 60;
		sec = sec < 10 ? "0" + sec : sec;
		if (min < 60) {
			runtimeMins = min + ":" + sec;
			runtimeHours = runtimeMins;
		}
		else {
			runtimeMins = min;
			if (parseInt(sec, 10) > 29) {
				runtimeMins++;
			}
			var hour = parseInt(min / 60, 10);
			min = min % 60;
			min = min < 10 ? "0" + min : min;
			runtimeHours = hour + ":" + min + ":" + sec;
		}
		program.runtime = runtimeMins;
		program.runtimeMins = runtimeMins;
		program.runtimeHours = runtimeHours;
		// Show info
		program.showName = program.showTitle;
		program.seasonName = program.seasonTitle;

		return program;
	}

	NEU.processSolrProgram = processSolrProgram;

	function processSolrPrograms(programs) {
		for (var i = 0; i < programs.length; i++) {
			processSolrProgram(programs[i]);
		}
		return programs;
	}

	NEU.processSolrPrograms = processSolrPrograms;

	/**
	 * Convert some solr specific attributes to app server version.
	 * @param GAME Solr Game Object
	 * @returns {*}
	 */
	function processSolrGame(game) {
		game.id = game.GAME_ID;
		game.seoName = game.SEO_NAME;
		game.gameState = game.GAME_STATE;
		if (game.FREE != null) {
			game.free = game.FREE ? 1 : 0;
		}
		if (game.GAME_DATE != null) {
			// Convert data format: solr format 2016-02-07T03:00:00Z -> app server format 2016-02-07T03:00:00.000
			game.dateTimeGMT = game.GAME_DATE.replace("Z", ".000");
		}
		if (game.GAME_DATE_LOCAL != null) {
			// Convert data format: solr format 2016-02-07T03:00:00Z -> app server format 2016-02-07T03:00:00.000
			game.date = game.GAME_DATE_LOCAL.replace("Z", ".000");
		}
		if (game.GAME_END_DATE != null) {
			// Convert data format: solr format 2016-02-07T03:00:00Z -> app server format 2016-02-07T03:00:00.000
			game.endDateTimeGMT = game.GAME_END_DATE.replace("Z", ".000");
		}
		if (game.GAME_END_DATE_LOCAL != null) {
			// Convert data format: solr format 2016-02-07T03:00:00Z -> app server format 2016-02-07T03:00:00.000
			game.endDateTime = game.GAME_END_DATE_LOCAL.replace("Z", ".000");
		}
		if (game.EXT_ID != null) {
			game.extId = game.EXT_ID;
		}
		if (game.NAME != null) {
			game.name = game.NAME;
		}
		if (game.DESCRIPTION != null) {
			game.description = game.DESCRIPTION;
		}
		if (game.IMAGE != null) {
			game.image = game.IMAGE;
		}
		if (game.LEAGUE_ID != null) {
			game.leagueId = game.LEAGUE_ID;
		}
		if (game.LEAGUE != null) {
			game.leagueName = game.LEAGUE;
		}
		if (game.SPORT_ID != null) {
			game.sportId = game.SPORT_ID;
		}
		if (game.SEASON != null) {
			game.season = game.SEASON;
		}
		if (game.GROUPING != null) {
			game.grouping = game.GROUPING;
		}
		if (game.AVAILABLE_PROGRAMS != null) {
			game.availablePrograms = game.AVAILABLE_PROGRAMS;
		}
		if (game.BLACKOUT_STATIONS != null) {
			game.blackoutStations = game.BLACKOUT_STATIONS;
		}
		if (game.NOTES != null) {
			game.notes = game.NOTES;
		}
		if (game.TBD != null) {
			game.tbd = game.TBD;
		}
		if (game.LIVE_EVENT_FLAG == true) {
			game.isGame = "false";
		}
		else {
			game.isGame = "true";
			if (game.RESULT != null) {
				game.result = game.RESULT;
			}
			//Away
			game.awayTeam = {};
			game.awayTeam.id = game.AWAY_ID;
			game.awayTeam.name = game.AWAY_TEAM;
			game.awayTeam.score = game.AWAY_SCORE || '';
			game.awayTeam.code = game.AWAY_CODE;
			//Home
			game.homeTeam = {};
			game.homeTeam.id = game.HOME_ID;
			game.homeTeam.name = game.HOME_TEAM;
			game.homeTeam.score = game.HOME_SCORE || '';
			game.homeTeam.code = game.HOME_CODE;
		}
		return game;
	}

	NEU.processSolrGame = processSolrGame;

	function processSolrGames(games) {
		for (var i = 0; i < games.length; i++) {
			processSolrGame(games[i]);
		}
		return games;
	}

	NEU.processSolrGames = processSolrGames;


	/**
	 * Convert some solr specific attributes to app server version.
	 * @param category Solr Category Object
	 * @returns {*}
	 */

	function processSolrCategory(category) {
		category.id = category.catId;
		category.isLeaf = category.leaf ? category.leaf : false;
		category.isShow = category.show ? category.show : false;
		if (category.episodes || category.episodes == 0) {
			category.programCount = category.episodes;
		}
		return category;
	}

	NEU.processSolrCategory = processSolrCategory;

	function processSolrCategories(categories) {
		for (var i = 0; i < categories.length; i++) {
			processSolrCategory(categories[i]);
		}
		return categories;
	}

	NEU.processSolrCategories = processSolrCategories;

	(function (window, document, NEU) {
		/***
		 * Load external page's <wrap></wrap> content
		 * @param {string} containerId
		 * @param {string} pageURL
		 * @param {string} targetTag
		 */
		function loadExternalPageContent(containerId, pageURL, targetTag) {
			loadPage(pageURL, function (data) {
				loadContent(data, containerId, targetTag);
			});
		}

		/***
		 * Load external page's <wrap></wrap> content
		 * @param {string} pageURL
		 * @param {function} callback, one contentCallback with content string parameter
		 * @param {string} targetTag
		 */
		function getExternalPageContent(pageURL, callback, targetTag) {
			loadPage(pageURL, function (data) {
				callback && callback(getContentString(data, targetTag));
			});
		}

		/***
		 * Load page and callback with return data
		 * @param {string} url
		 * @param {function} callback
		 * @param {object} [context]
		 */
		function loadPage(url, callback, context) {
			// Use XDomainRequest when browser <= IE9, otherwise use XMLHttpRequest
			if (window.XDomainRequest) {
				xdrGetRequest(url, callback, context)
			}
			else {
				xmlHTTPGetRequest(url, callback, context);
			}
		}

		/***
		 * Set content to element
		 * @param {string} content
		 * @param {string} containerId
		 * @param {string} targetTag
		 */
		function loadContent(content, containerId, targetTag) {
			var containerElement = document.getElementById(containerId);
			if (containerElement) {
				containerElement.innerHTML = getContentString(content, targetTag);
			}
		}

		/***
		 * Get content string
		 * @param content
		 * @param targetTag
		 * @return {string}
		 */
		function getContentString(content, targetTag) {
			var searchTag = targetTag || 'wrap',
					tagHTMLArray = searchTagMatches(searchTag, processContentString(content));
			return tagHTMLArray ? tagHTMLArray[0] : '';
		}

		/***
		 * Process content data which may contain double quotes
		 * @param content
		 * @returns {string}
		 */
		function processContentString(content) {
			var textArea = document.createElement('textarea');
			textArea.innerText = content.toString();
			return textArea.value;
		}

		/***
		 * Search tag in html string
		 * @param {string} htmlTag
		 * @param {string} sourceStr
		 * @returns {RegExpExecArray | null}
		 */
		function searchTagMatches(htmlTag, sourceStr) {
			var htmlTagExp = "<" + htmlTag + "[^>]*>((.|\n)*?)<\/" + htmlTag + ">";
			return new RegExp(htmlTagExp).exec(sourceStr);
		}

		/***
		 * Send get request in IE <= 9
		 * @param {string} url
		 * @param {function} callback
		 * @param {object} [context]
		 *
		 * XDomainRequest is an implementation of HTTP access control (CORS) that worked in Internet Explorer 8 and 9.
		 * It was removed in Internet Explorer 10 in favor of using XMLHttpRequest with proper CORS;
		 * https://developer.mozilla.org/zh-CN/docs/Web/API/XDomainRequest
		 */
		function xdrGetRequest(url, callback, context) {
			var xdr = new XDomainRequest();
			if (xdr) {
				xdr.onload = function () {
					callback && (context ? context[callback](xdr.responseText) : callback(xdr.responseText));
				};
				xdr.onerror = function () {
					/* error handling here */
				};
				xdr.open('GET', url);
				xdr.send();
			}
		}

		/***
		 * Send get request in IE >= 10 or other browsers
		 * @param {string} url
		 * @param {function} callback
		 * @param {object} [context]
		 */
		function xmlHTTPGetRequest(url, callback, context) {
			var request = new XMLHttpRequest();
			request.open('GET', url, true);
			request.onload = function () {
				if (request.status >= 200 && request.status < 400) {
					// Success
					callback && (context ? context[callback](request.responseText) : callback(request.responseText));
				}
				else {
					// We reached our target server, but it returned an error
				}
			};
			request.onerror = function () {
				// There was a connection error of some sort
			};
			request.send();
		}

		NEU.loadExternalPageContent = loadExternalPageContent;
		NEU.getExternalPageContent = getExternalPageContent;
	})(window, document, NEU);

	/**
	 * Share link to facebook and twitter
	 * @param type 'facebook' or 'twitter'
	 * @param path Page url
	 * @param name Page name
	 */
	function socialShare(type, path, name) {
		var url = null;
		if (path == '' || path == undefined)
			path = window.location.href;
		switch (type) {
			case "facebook":
				url = "http://www.facebook.com/sharer.php?u=" + encodeURIComponent(path);
				if (window.NLTracker != null)
					NLTracker.trackPage("/share/facebook");
				else if (window.nlTrackSubPage != null)
					nlTrackSubPage("share", {type: type, name: name});
				break;
			case "twitter":
				path = path.replace(/\*/g, "%2A");
				url = "http://twitter.com/share?url=" + encodeURIComponent(path);
				if (name) {
					url += "&text=" + encodeURIComponent(document.getElementById("msg_share_text").innerHTML.replace(/\{0\}/, name));
				}
				if (window.NLTracker != null)
					NLTracker.trackPage("/share/twitter");
				else if (window.nlTrackSubPage != null)
					nlTrackSubPage("share", {type: type, name: name});
				break;
			default:
				break;
		}
		if (url != null) {
			var newwin = window.open(url, "", "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600");
			if (type == "mail" && newwin != null)
				newwin.close();
		}
	}

	NEU.socialShare = socialShare;

	/**
	 * escape special chars
	 * @param str
	 * @return {*}
	 */
	function escapeHTML(str) {
		var map = {
			'&': '&amp;',
			'<': '&lt;',
			'>': '&gt;',
			'"': '&quot;',
			"'": '&#039;'
		};

		return str.replace(/[&<>"']/g, function (m) {
			return map[m];
		});
	}

	NEU.escapeHTML = escapeHTML;

	function trackLinkClick(element, isJumpLink) {
		if (window.NLTracker != null && NLTracker.trackVideoClick) {
			var trackingTimeout = 250;

			var id = element.getAttribute("data-id"),
					type = element.getAttribute("data-track-type"),
					page = element.getAttribute("data-track-page"),
					section = element.getAttribute("data-track-section"),
					recommendListId = element.getAttribute("data-track-recommend-list-id");
			var trackingData = {"id": id, "type": type, "page": page, "section": section};
			if (recommendListId) {
				trackingData["recommendListId"] = recommendListId;
			}
			NLTracker.trackVideoClick(trackingData);
			var href = element.href;
			if (isJumpLink) {
				setTimeout(function () {
					location.href = href;
				}, trackingTimeout);
				event.preventDefault();
			}
		}
		// More other common trackers can go here.
	}

	NEU.trackLinkClick = trackLinkClick;

	function bindLinkTracking(domId) {
		var element = document;
		if (domId != undefined) {
			element = document.getElementById(domId);
			if (element == null) {
				NEU.logger.error(domId + " hasn't been found, bindLinkTracking() init failed.");
				return;
			}
		}
		element.addEventListener("click", function (event) {
			// right clicks (which == 3) on Firefox trigger a click event.
			if (event.which == 3)
				return;

			var link = event.target;
			// Find the nearest parent anchor element
			while (link != null && link.tagName !== 'A') {
				link = link.parentNode;
			}
			if (link && link.tagName === 'A' && link.getAttribute("data-link-tracking") != undefined) {
				// Ref: GA https://github.com/googleanalytics/autotrack/blob/master/lib/plugins/outbound-link-tracker.js
				var isJumpLink = !(
						// Middle mouse button clicks (which == 2) are used to open a link in a new tab, and
						event.which == 2 ||
						// On mac, command clicking will open a link in a new tab. Control
						// clicking does this on windows.
						event.metaKey || event.ctrlKey ||
						// Shift clicking in Chrome/Firefox opens the link in a new window
						// In Safari it adds the URL to a favorites list.
						event.shiftKey ||
						// On Mac, clicking with the option key is used to download a resouce.
						event.altKey
				);
				isJumpLink = (link.href && link.href.indexOf("javascript") == -1) && link.target != "_blank" && isJumpLink;
				// SPA framework will intercept link request and forward to their router to process, 
				// and meanwhile call event.preventDefault() to stop link redirection.
				// This way, we can check event.defaultPrevented to see if we need to simulate link redirect behavior.
				isJumpLink = !event.defaultPrevented && isJumpLink;

				trackLinkClick(link, isJumpLink);
			}
		});
	}

	NEU.bindLinkTracking = bindLinkTracking;

	function unbindLinkTracking(domId) {
		// TODO
	}

	// NEU.unbindLinkTracking = unbindLinkTracking;

	/**
	 * Escape string for js parameter
	 * @param str
	 * @return {*}
	 */
	function escapeJS(str) {
		return escapeHTML(str.replace(/[\\]/g, '\\\\').replace(/["]/g, '\\\"').replace(/[']/g, "\\\'"));
	}

	NEU.escapeJS = escapeJS;

	/**
	 * Generate UUID
	 * Ref: https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
	 * To reduce the chance of collisions, introduce timestamp to make sure the generated id is session unique.
	 */
	function createUUID() {
		var m = 4294967296; // Math.pow(16, 8)
		var t = (NEU.now() | m).toString(16);
		return t + '-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
					var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
					return v.toString(16);
				});
	}

	NEU.createUUID = createUUID;

	/**
	 * Get sort param value for solr query category's program with category's sortBy value
	 * Ref: base - CategoryService.java
	 * @param catId
	 * @param sortBy, eg: undefined, 1, 2, 3, 4, 5
	 * @return {string}
	 */
	function getProgramSolrSortParam(catId, sortBy) {
		let sortParam = '';
		switch (sortBy) {
			case 2:
				sortParam = `rank_${catId} asc,updateDate desc`;
				break;
			case 3:
				sortParam = "releaseDate desc";
				break;
			case 4:
				sortParam = "updateDate desc";
				break;
			case 5:
				sortParam = "episode asc";
				break;
			default:
				// case : undefined, 1
				sortParam = `rank_${catId} asc,releaseDate desc`;
		}
		return encodeURIComponent(sortParam);
	}

	NEU.getProgramSolrSortParam = getProgramSolrSortParam;

	/**
	 * Get Game Solr region param value
	 * Ref: base - CategoryService.java
	 * @param contentRegions eg: [{"id":1,"name":"Allow Region 1"},{"id":2,"name":"Allow Region 2"},{"id":3,"name":"Deny Region 3", isDeny: true},{"id":4,"name":"Deny Region 4", isDeny: true},]
	 * @return {string}
	 */
	function getGameSolrRegionParam(contentRegions) {
		let sortParam = '(*:*%20-REGION_IDS_ALLOW:*%20-REGION_IDS_DENY:*)';
		let allowIds = [];
		let denyIds = [];
		if(contentRegions && contentRegions.length > 0)
		{
			for (let i = 0; i < contentRegions.length; i++)
			{
				if(contentRegions[i].isDeny)
				{
					denyIds[denyIds.length] = contentRegions[i].id;
				}
				else
				{
					allowIds[allowIds.length] = contentRegions[i].id;
				}
			}
			if(denyIds.length > 0)
			{
				sortParam += '%20OR%20(-REGION_IDS_DENY:(' + denyIds.join('%20') + ')';
			}
			else
			{
				sortParam = '(*:*%20-REGION_IDS_ALLOW:*)%20OR';
			}
			if(allowIds.length > 0)
			{
				sortParam += '%20REGION_IDS_ALLOW:(' + allowIds.join('%20') + ')';
				if(denyIds.length > 0)
				{
					sortParam += ')';
				}
			}
			else
			{
				sortParam += ')';
			}
		}
		else
		{
			sortParam = '(*:*%20-REGION_IDS_ALLOW:*)';
		}
		return sortParam;
	}

	NEU.getGameSolrRegionParam = getGameSolrRegionParam;

	/**
	 * Get Game Solr region param value
	 * Ref: base - CategoryService.java
	 * @param contentRegions eg: [{"id":1,"name":"Allow Region 1"},{"id":2,"name":"Allow Region 2"},{"id":3,"name":"Deny Region 3", isDeny: true},{"id":4,"name":"Deny Region 4", isDeny: true},]
	 * @return {string}
	 */
	function getProgramSolrRegionParam(contentRegions) {
		let sortParam = '(*:*%20-regionId:*)';
		let allowIds = [];
		let denyIds = [];
		if(contentRegions && contentRegions.length > 0)
		{
			for (let i = 0; i < contentRegions.length; i++)
			{
				if(contentRegions[i].isDeny)
				{
					denyIds[denyIds.length] = contentRegions[i].id;
				}
				else
				{
					allowIds[allowIds.length] = contentRegions[i].id;
				}
			}
			if(denyIds.length > 0)
			{
				sortParam += '%20OR%20(regionIdDeny:[*%20TO%20*]%20-regionIdDeny:(' + denyIds.join('%20') + '))';
			}
			else
			{
				sortParam = '(*:*%20-regionIdAllow:*)';
			}
			if(allowIds.length > 0)
			{
				sortParam += '%20OR%20regionIdAllow:(' + allowIds.join('%20') + ')';
			}
		}
		else
		{
			sortParam = '(*:*%20-regionIdAllow:*)';
		}
		return sortParam;
	}

	NEU.getProgramSolrRegionParam = getProgramSolrRegionParam;

	/**
	 * filter current user type
	 * @param userType e.g:
			 null - All
			 0 - Anonymous
			 1 - Authenticated (non-subscriber)
			 2 - Authenticated (subscriber or non-subscriber)
			 3 - Subscriber
	 		 4 - Non-subscriber (anonymous or authenticated)
	 * @param isLoggedIn
	 * @param isSubscriber
	 * @return {boolean}
	 */
	function filterUserType(userType, isLoggedIn, isSubscriber) {
		let ret = false;
		if((userType === null || userType === undefined) || (userType === 0 && !isLoggedIn) || (userType === 1 && isLoggedIn && !isSubscriber) || (userType === 2 && isLoggedIn) || (userType === 3 && isLoggedIn && isSubscriber)|| (userType === 4 && (!isLoggedIn || !isSubscriber)))
		{
			ret = true;
		}
		return ret;
	}
	NEU.filterUserType = filterUserType;

	/**
	 * process nlUserStatus (Neulion user status) which can used for filterUserTypeByStatus
	 * @param isLoggedIn
	 * @param isSubscriber
	 * @return null/anonymous/authenticated/authorized
	 */
	function processNlUserStatus(isLoggedIn, isSubscriber)
	{
		let ret = null;
		if(isLoggedIn === true)
		{
			if(isSubscriber)
			{
				ret = "authorized";
			}
			else
			{
				ret = "authenticated";
			}
		}
		else if(isLoggedIn === false)
		{
			ret = "anonymous";
		}
		return ret;
	}
	NEU.processNlUserStatus = processNlUserStatus;

	/**
	 * filter current user type
	 * @param nlUserStatus
		The Status of Neulion User. e.g: null/anonymous/authenticated/authorized
		null mean Neulion Account In is NOT support.
	 * @param thirdUserStatus
		The Status of Third party User. e.g: null/anonymous/authenticated/authorized
		null mean Third party Account is NOT support.
	 * @return {boolean}
	 * Confluence: https://confluence.neulion.com/pages/viewpage.action?pageId=328271764
	 */
	function filterUserTypeByStatus(userType, nlUserStatus, thirdUserStatus) {
		let ret = false;
		if(
				(userType === null || userType === undefined) ||
				(userType === 0 && (nlUserStatus === "anonymous" || thirdUserStatus === "anonymous")) ||
				(userType === 1 && (nlUserStatus === "authenticated")) ||
				(userType === 2 && (nlUserStatus === "authenticated" || nlUserStatus === "authorized")) ||
				(userType === 3 && (nlUserStatus === "authorized")) ||
				(userType === 4 && (nlUserStatus === "anonymous" || nlUserStatus === "authenticated" || thirdUserStatus === "anonymous" || thirdUserStatus === "authenticated")) ||
				(userType === 5 && (thirdUserStatus === "authorized")) ||
				(userType === 6 && (nlUserStatus === "authenticated" || thirdUserStatus === "authorized")) ||
				(userType === 7 && (nlUserStatus === "authorized" || thirdUserStatus === "authorized")) ||
				(userType === 8 && (nlUserStatus === "authenticated" || nlUserStatus === "authorized" || thirdUserStatus === "authorized"))
		)
		{
			ret = true;
		}
		return ret;
	}
	NEU.filterUserTypeByStatus = filterUserTypeByStatus;

	// Backward compatible
	NEU.util = {};
	var utils = ["domready", "getLocalizedString", "parseDate", "formatDate", "formatDateToLocalDate",
		"convertToLocalDate", "setCookie", "getCookie", "delCookie", "getScript", "getJSONPFeed", "getJSFeed", "getJSON",
		"addTimestamp", "extendFunction", "checkH5PlayerCompatibility", "processSolrProgram", "processSolrPrograms",
		"processSolrGame", "processSolrGames", "loadExternalPageContent", "getExternalPageContent",
		"socialShare", "escapeHTML", "escapeJS", "DateUtil"];
	for (var i = 0; i < utils.length; i++) {
		NEU.util[utils[i]] = NEU[utils[i]];
	}

	// --------------------- Util End ---------------------

	// Backward compatible
	NEU.ui = {};

	// ------------------- Class ---------------------
	NEU.event = new Event();

	class GDPRCookie {
		static getInstance(options) {
			if (!this.instance) {
				this.instance = new GDPRCookie(options);
			}
			return this.instance;
		}

		constructor(options) {
			if (!GDPRCookie.instance) {
				this.COOKIE_CONFIG_KEY = "SITE_COOKIE_ALERT_CONFIG";
				this.CHANGED_EVENT = "CHANGED_Event";
				this.accepted = false;
				this.refused = false;
				this.ignored = false;
				this.initedDom = false;
				this.itemClass = {
					closeBtn: 'gdpr-close-btn',
					acceptBtn: 'gdpr-accept-btn',
					refuseBtn: 'gdpr-refuse-btn',
					ignoreBtn: 'gdpr-ignore-btn'
				};
				this.setOptions(options);
			}

			return GDPRCookie.instance;
		}

		setOptions(options) {
			// Default 365 days
			this.expiredDays = this.expiredDays !== undefined ? this.expiredDays : 365;
			if (options && options.expiredDays !== undefined) {
				this.expiredDays = options.expiredDays;
			}
			this.COOKIE_CONFIG_VALUE = "1";
			if (!!options && !!options.itemClass) {
				this.itemClass = {...this.itemClass, ...options.itemClass};
			}
		}

		isAccepted() {
			return this.accepted || this.refused || getCookie(this.COOKIE_CONFIG_KEY) !== null;
		}

		accept() {
			this.accepted = true;
			setCookie(this.COOKIE_CONFIG_KEY, this.COOKIE_CONFIG_VALUE, this.expiredDays);
			this.event && this.event.trigger(this.CHANGED_EVENT, true);
		}

		refuse() {
			this.refused = true;
			this.event && this.event.trigger(this.CHANGED_EVENT, false);
		}

		ignore() {
			this.ignored = true;
			this.event && this.event.trigger(this.CHANGED_EVENT);
		}

		listen(callback) {
			if (!this.event) {
				this.event = new Event();
			}
			this.event.on(this.CHANGED_EVENT, callback);
		}

		show(options, showCallback, respondCallback) {
			let isShown = false;
			if (!this.isAccepted()) {
				this.setOptions(options);

				let showNotice = () => {
					if (typeof showCallback === 'function') {
						showCallback();
					}
					else if (options.containerId) {
						document.getElementById(options.containerId).style.display = 'block';
					}
					if (!this.initedDom) {
						let containerElement = (!!options && !!options.containerId) ? document.getElementById(options.containerId) : document;
						if (!!containerElement) {
							this.initedDom = true;
							[].slice.call(containerElement.querySelectorAll(`.${this.itemClass.closeBtn}`))
									.forEach(itemBtn => {
										itemBtn.addEventListener('click', () => this.accept());
									});
							[].slice.call(containerElement.querySelectorAll(`.${this.itemClass.acceptBtn}`))
									.forEach(itemBtn => {
										itemBtn.addEventListener('click', () => this.accept());
									});
							[].slice.call(containerElement.querySelectorAll(`.${this.itemClass.refuseBtn}`))
									.forEach(itemBtn => {
										itemBtn.addEventListener('click', () => this.refuse());
									});
							[].slice.call(containerElement.querySelectorAll(`.${this.itemClass.ignoreBtn}`))
									.forEach(itemBtn => {
										itemBtn.addEventListener('click', () => this.ignore());
									});
						}
					}
				};
				let respondNotice = (isAccepted) => {
					if (typeof respondCallback === 'function') {
						respondCallback(isAccepted);
					}
					else if (options.containerId) {
						document.getElementById(options.containerId).style.display = 'none';
					}
				};

				showNotice();
				this.listen(respondNotice);
				isShown = true;
			}
			return isShown;
		}
	}

	NEU.GDPRCookie = GDPRCookie;
	NEU.gdprCookie = GDPRCookie.getInstance();

	window.NEU = NEU;

	// Compatible with webpack
	if (typeof exports === 'object' && typeof module === 'object') {
		module.exports = NEU;
	}
})(window, document, module);